import publicApi from "@/api/public"

const {
	publicGetExtraData,
	publicGetOrganizationList,
	publicGetuserList,
	publicGetDepartmentList,
	publicGetDictionaryList
} = publicApi

export default {
	methods: {
		// 监听扩展字段的修改
		handleExtraValueChange(key, value, formKey = "formData") {
			this[formKey][key] = value
		},
		// 获取扩展字段的集合
		getExtraData(formKey = "formData") {
			// 将扩展字段保存为key:value的形式，以便后期做数据筛选
			const extraData = {}
			this.extraList.forEach(item => {
				extraData[item.bindCode] = this[formKey][item.bindCode]
			})
			return extraData
		},
		// 获取扩展字段的列表
		getExtraList(code) {
			this.$asyncDo(async () => {
				const res = await publicGetExtraData({ code })
				if (res) {
					const extraList = []
					if (res.data.length) {
						res.data.forEach(item => {
							if (!item.isDel) {
								const s = JSON.parse(item.data)
								if (s.customRules) {
									s.customRules = s.customRules.map(rule => {
										const cusRule = {
											...rule.rule,
											pattern: rule.rule.pattern
												? eval(rule.rule.pattern)
												: null // 转正则格式
											// pattern: rule.rule.pattern ? new RegExp(rule.rule.pattern) : null, // 转正则格式
										}
										return cusRule
									})
								}
								extraList.push(s)
							}
						})
					}
					this.extraList = extraList
					// 动态获取关联数据
					this.getDynamicList()
				}
			})
		},
		getDynamicList() {
			this.extraList.forEach(el => {
				this.getSelList(el)
			})
		},
		// 根据不同的类型获取列表
		getSelList(el) {
			let res = null
			this.$asyncDo(async () => {
				// 获取数据字典关联数据
				if (el.type.indexOf("dictionary") > -1) {
					const type = el.type.split("dictionary_")[1]
					res = await publicGetDictionaryList({ isDel: 0, type })
					if (res) {
						Object.assign(el, {
							selectList: res.data[0].leaf
						})
					}
				}
				switch (el.type) {
					// 获取组织关联数据
					case "dynamic_ORG":
						res = await publicGetOrganizationList()
						if (res) {
							Object.assign(el, {
								selectList: res.data
							})
						}
						break
					// 获取用户关联数据
					case "dynamic_USER":
						res = await publicGetuserList()
						if (res) {
							res.data = res.data.map(o => ({ name: o.realName }))
							Object.assign(el, {
								selectList: res.data
							})
						}
						break
					// 获取部门关联数据
					case "dynamic_DEPARTMENT":
						res = await publicGetDepartmentList()
						if (res) {
							Object.assign(el, {
								selectList: res.data
							})
						}
						break
					default:
						break
				}

				return el
			})
		}
	},
	created() {}
}
