<template>
	<Row :gutter="32">
		<Col v-for="item in list" :span="item.width" :key="item.bindCode">
			<FormItem
				:label="item.name"
				:prop="item.bindCode"
				:rules="item.customRules"
			>
				<!--单行文本框-->
				<template v-if="item.type === 'base_Input'">
					<span v-if="formType === 'view'">
						{{ data[item.bindCode] ? data[item.bindCode] : "--" }}
					</span>
					<Input
						v-else
						type="text"
						@on-change="e => valueChange(item.bindCode, e.target.value)"
						v-model="data[item.bindCode]"
						:placeholder="item.placeholder"
					/>
				</template>

				<!--多行文本框-->
				<template v-if="item.type === 'base_Textarea'">
					<span v-if="formType === 'view'">
						{{ data[item.bindCode] ? data[item.bindCode] : "--" }}
					</span>
					<Input
						v-else
						:rows="item.rows"
						type="textarea"
						@on-change="e => valueChange(item.bindCode, e.target.value)"
						v-model="data[item.bindCode]"
						:placeholder="item.placeholder"
					/>
				</template>
				<!--日期-->
				<template v-if="item.type === 'base_Date'">
					<span v-if="formType === 'view'">
						{{
							data[item.bindCode]
								? moment(data[item.bindCode]).format("YYYY-MM-DD")
								: "--"
						}}
					</span>
					<DatePicker
						v-else
						:type="item.bindType"
						:placeholder="item.placeholder"
						@on-change="value => valueChange(item.bindCode, value)"
						:value="data[item.bindCode]"
						style="width: 100%"
					>
					</DatePicker>
				</template>

				<!--下拉选项-->
				<template v-if="item.type === 'base_Select'">
					<span v-if="formType === 'view'">
						{{
							getValueByCode(item, "optionList", "value")
								? getValueByCode(item, "optionList", "value")
								: "--"
						}}
					</span>
					<Select
						v-else
						@on-change="value => valueChange(item.bindCode, value)"
						v-model="data[item.bindCode]"
						:placeholder="item.placeholder"
						style="width: 100%"
					>
						<Option
							v-for="(op, idx) in item.optionList"
							:value="op.value"
							:key="idx"
							>{{ op.value }}
						</Option>
					</Select>
				</template>

				<!--单选选项-->
				<template v-if="item.type === 'base_Radio'">
					<span v-if="formType === 'view'">
						{{
							getValueByCode(item, "radioList", "value")
								? getValueByCode(item, "radioList", "value")
								: "--"
						}}
					</span>
					<RadioGroup
						v-else
						@on-change="value => valueChange(item.bindCode, value)"
						:placeholder="item.placeholder"
						v-model="data[item.bindCode]"
					>
						<Radio
							v-for="(op, idx) in item.radioList"
							:key="idx"
							:label="op.value"
						>
						</Radio>
					</RadioGroup>
				</template>

				<!--动态获取列表 关联数据和数据字典关联列表展示-->
				<template
					v-if="
						item.type.indexOf('dynamic') > -1 ||
						item.type.indexOf('dictionary') > -1
					"
				>
					<span v-if="formType === 'view'">
						{{
							getValueByCode(item, "selectList", "name")
								? getValueByCode(item, "selectList", "name")
								: "--"
						}}
					</span>
					<Select
						v-else
						@on-change="value => valueChange(item.bindCode, value)"
						v-model="data[item.bindCode]"
						:placeholder="item.placeholder"
						style="width: 100%"
					>
						<Option
							v-for="(op, idx) in item.selectList"
							:value="op.name"
							:key="idx"
							>{{ op.name }}
						</Option>
					</Select>
				</template>
			</FormItem>
		</Col>
	</Row>
</template>
<script>
import moment from "moment"

export default {
	name: "extraForm",
	props: ["list", "data", "formType"],
	data() {
		return {
			moment
		}
	},
	methods: {
		valueChange(key, value) {
			this.$emit("extraValueChange", key, value)
		},
		// 获取下拉列表的值
		getValueByCode(item, listType, valueType) {
			const value = this.data[item.bindCode]
			let result = ""
			if (value) {
				for (let i = 0; i < item[listType].length; i += 1) {
					const option = item[listType][i]
					if (value.indexOf(option[valueType]) > -1) {
						result += `${option[valueType]};`
					}
				}
			}
			return result
		}
	}
}
</script>
