var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Row',{attrs:{"gutter":32}},_vm._l((_vm.list),function(item){return _c('Col',{key:item.bindCode,attrs:{"span":item.width}},[_c('FormItem',{attrs:{"label":item.name,"prop":item.bindCode,"rules":item.customRules}},[(item.type === 'base_Input')?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.data[item.bindCode] ? _vm.data[item.bindCode] : "--")+"\n\t\t\t\t")]):_c('Input',{attrs:{"type":"text","placeholder":item.placeholder},on:{"on-change":e => _vm.valueChange(item.bindCode, e.target.value)},model:{value:(_vm.data[item.bindCode]),callback:function ($$v) {_vm.$set(_vm.data, item.bindCode, $$v)},expression:"data[item.bindCode]"}})]:_vm._e(),(item.type === 'base_Textarea')?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.data[item.bindCode] ? _vm.data[item.bindCode] : "--")+"\n\t\t\t\t")]):_c('Input',{attrs:{"rows":item.rows,"type":"textarea","placeholder":item.placeholder},on:{"on-change":e => _vm.valueChange(item.bindCode, e.target.value)},model:{value:(_vm.data[item.bindCode]),callback:function ($$v) {_vm.$set(_vm.data, item.bindCode, $$v)},expression:"data[item.bindCode]"}})]:_vm._e(),(item.type === 'base_Date')?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.data[item.bindCode]
							? _vm.moment(_vm.data[item.bindCode]).format("YYYY-MM-DD")
							: "--")+"\n\t\t\t\t")]):_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":item.bindType,"placeholder":item.placeholder,"value":_vm.data[item.bindCode]},on:{"on-change":value => _vm.valueChange(item.bindCode, value)}})]:_vm._e(),(item.type === 'base_Select')?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.getValueByCode(item, "optionList", "value")
							? _vm.getValueByCode(item, "optionList", "value")
							: "--")+"\n\t\t\t\t")]):_c('Select',{staticStyle:{"width":"100%"},attrs:{"placeholder":item.placeholder},on:{"on-change":value => _vm.valueChange(item.bindCode, value)},model:{value:(_vm.data[item.bindCode]),callback:function ($$v) {_vm.$set(_vm.data, item.bindCode, $$v)},expression:"data[item.bindCode]"}},_vm._l((item.optionList),function(op,idx){return _c('Option',{key:idx,attrs:{"value":op.value}},[_vm._v(_vm._s(op.value)+"\n\t\t\t\t\t")])}),1)]:_vm._e(),(item.type === 'base_Radio')?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.getValueByCode(item, "radioList", "value")
							? _vm.getValueByCode(item, "radioList", "value")
							: "--")+"\n\t\t\t\t")]):_c('RadioGroup',{attrs:{"placeholder":item.placeholder},on:{"on-change":value => _vm.valueChange(item.bindCode, value)},model:{value:(_vm.data[item.bindCode]),callback:function ($$v) {_vm.$set(_vm.data, item.bindCode, $$v)},expression:"data[item.bindCode]"}},_vm._l((item.radioList),function(op,idx){return _c('Radio',{key:idx,attrs:{"label":op.value}})}),1)]:_vm._e(),(
					item.type.indexOf('dynamic') > -1 ||
					item.type.indexOf('dictionary') > -1
				)?[(_vm.formType === 'view')?_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.getValueByCode(item, "selectList", "name")
							? _vm.getValueByCode(item, "selectList", "name")
							: "--")+"\n\t\t\t\t")]):_c('Select',{staticStyle:{"width":"100%"},attrs:{"placeholder":item.placeholder},on:{"on-change":value => _vm.valueChange(item.bindCode, value)},model:{value:(_vm.data[item.bindCode]),callback:function ($$v) {_vm.$set(_vm.data, item.bindCode, $$v)},expression:"data[item.bindCode]"}},_vm._l((item.selectList),function(op,idx){return _c('Option',{key:idx,attrs:{"value":op.name}},[_vm._v(_vm._s(op.name)+"\n\t\t\t\t\t")])}),1)]:_vm._e()],2)],1)}),1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }